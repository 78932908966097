

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";

import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Antifaq({setIsModalOpen}) {
    return (
        <Section py={4} id="faq" bg="#ffffff" className="pb-md-5 mb pt-5 mt-2 pt-md-5">
          <Container className="pb-md-5 mb pt-md-5">
            <Row className="justify-content-center">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about <span>Anti-wrinkle</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="pt-5">


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >


                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is anti wrinkle treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Anti Wrinkle injections are commonly employed to reduce the appearance of facial wrinkles and
                        fine lines by temporarily paralysing the underlying muscles. It's often used to treat wrinkles
                        around the eyes (crow's feet), forehead lines, and frown lines between the eyebrows.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What does the treatment involve?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Before undergoing treatment, you'll have a consultation with a qualified practitioner. The
                        provider will assess whether you're a suitable candidate for the treatment and explain what to
                        expect during and after the procedure. During the treatment, a fine needle is used to inject
                        small amounts of Botulinum Toxin into specific muscles in the target area. The number of
                        injections and the amount of units used will depend on the area being treated and the desired
                        results.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which areas can be treated with Anti Wrinkle injections?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Frownlines<br />
                        Gummy Smile<br />
                        Forehead lines<br />
                        Crows feet<br />
                        Brow lift<br />
                        Downturned corner of the mouth<br />
                        Dimpled chin<br />
                        Masseter Muscle<br />
                        Lip Flip
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long does the Anti Wrinkle treatment take?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In general, a single treatment session typically takes around 10 minutes to complete.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does Anti Wrinkle treatment hurt?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The level of discomfort experienced during an anti-wrinkle treatment with injections can vary
                        from person to person. Some individuals may experience only mild discomfort, while others may
                        find the injections slightly more uncomfortable.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long is recovery? Will the anti wrinkle treatment leave any marks or bruising?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        After your Anti Wrinkle treatment, there is no recovery time needed; you can get straight back
                        to your daily activities or work that does not include any physical activity or sun exposure,
                        the very same day. You may experience some mild redness, swelling, or bruising at the injection
                        sites immediately after the procedure. These effects are usually temporary and typically subside
                        within a few hours to a few days.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is the Anti wrinkle treatment safe?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Overall, Anti-Wrinkle injections treatment have a well-established safety profile when performed
                        by qualified professionals. It's important to discuss any concerns or questions you may have
                        with your practitioner, and to follow their instructions for pre- and post-treatment care to
                        minimise the risk of complications.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What happens 3-6 months after my anti wrinkle treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Three to six months after your anti-wrinkle treatment when you may start to notice the gradual
                        return of muscle activity and the reappearance of wrinkles. Maintenance treatments can help
                        sustain the results and keep your skin looking smooth and youthful over time.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How much is Anti Wrinkle treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        At Dermamina, the price for one area of anti wrinkle starts from $190, $270 for two areas and
                        $340 for three areas.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is anti wrinkle treatment permanent?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, anti-wrinkle treatments are not permanent. The effects are temporary, and the
                        muscle-relaxing effects typically last for about 3 to 6 months on average. After this period,
                        muscle activity gradually returns to the treated areas, leading to the gradual reappearance of
                        wrinkles and fine lines.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What should I avoid after Anti Wrinkle treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        After undergoing an anti-wrinkle treatment, there are several things you may be advised to avoid
                        to optimise the results and minimise the risk of complications. Here are some common
                        recommendations: <br /> <br />
                        <ul>
                          <li>Avoid touching or rubbing the treated area</li>
                          <li>Avoid strenuous exercise</li>
                          <li>Avoid excessive heat or sunlight</li>
                          <li>Avoid alcohol consumption</li>
                          <li>Avoid blood-thinning medications</li>
                          <li>Follow post-treatment instructions</li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will I get a consultation before the non surgical rhinoplasty treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, all appointments begin with a thorough consultation. At Dermamina London, our lovely
                        practitioners take a holistic approach, considering patients' concerns and expectations and
                        supporting the patient before and after the treatment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Am I suitable for Anti Wrinkle treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We do suggest booking in for a consultation if you are unsure whether the treatment will help
                        achieve the results you are looking for.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I have other dermal filler treatments along with Anti Wrinkle treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, dermal filler treatments can be done all on the same day as your anti wrinkle treatment,
                        upon assessment and suitability.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Anti Wrinkle treatment in London? Why choose Dermamina London?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        When considering non-surgical treatments, it is important to go to a professional and
                        specialised provider that has great knowledge and experience with non-surgical treatments. An
                        experienced and specialised practitioner will know what to do to deliver the desired results
                        while minimising the risks and side effects and avoiding any complications. Dermamina London is
                        renowned for providing natural-looking, yet life-changing results. We are committed to providing
                        clinically advanced, evidence-based aesthetic treatments, with safety and honesty as our number
                        one priority; we do not recommend any treatments if they are not suitable for you. Do not just
                        take our word for it, check out our amazing reviews all left by our valued patients and not to
                        mention the vast portfolio of results all performed by our highly experienced, medically
                        trained, most loving practitioner, Mina.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />


                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What areas are Included? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Frownlines<br />
                        Gummy Smile<br />
                        Forehead lines<br />
                        Crows feet<br />
                        Brow lift<br />
                        Downturned corner of the mouth<br />
                        Dimpled chin<br /><br />
                        We start every treatment with a consultation to understand what you are trying to achieve.
                        Treatments usually takes 10-15 minutes, excluding consultation time. The results show around 7
                        to 10 days after treatment, and lasts approximately 6 to 12 months, however this all varies from
                        person to person.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What does the anti-ageing treatment involve? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The treatment is carried out by using a needle to inject into the correct areas, providing well
                        suited results according to the patient's requirements.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />

                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What happens on the day of the anti-ageing treatment? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We start with a thorough consultation to assess your face and listen to your requirements.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />

                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the Side effects of Anti-Wrinkle treatment? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Minor redness or swelling at the injected site, which subsides within an hour, and rarely any
                        bruising.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />
              </Col>


            </Row>


            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active">
              <Bookbutton>Book appointment</Bookbutton>
            </div>


          </Container>
        </Section>


    );
}







