import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import HeroComponent from "../components/HeroComponent";
import LipsGlance from "../components/glance.js";
import imgVideo from "../assets/image/jpeg/lip-filler-doctor.png";
import Testimonial from "../sections/index/indexreviews.js";
import Saveface from '../components/save.js';
import Trend from '../components/trends.js';
import Content from "../sections/anti/anticontent.js";
import Content2 from "../sections/anti/anticontent2.js";
import LipsFaq from "../sections/anti/antifaq.js";
import AntiGallery from "../sections/anti/antigallery.js";
import Head from "../components/head";
import Clinic from '../components/NewFind.js';  
import { JsonLd } from '../components/JsonLd';
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const ExoPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
<Head title="Professional Anti-Wrinkle Treatments London UK" image="/images/dermamina-home.jpg"  description="experts in Anti-wrinkle treatments London" keywords="anti-ageing, skincare, Botox, retinol, collagen, hyaluronic acid, peptides, microdermabrasion, laser therapy, chemical peels"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
'@type': 'ContactPoint',
telephone: '020 7099 6650',
contactType: 'Customer service',
},
address:{
'@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
{'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Profhilo London',
item:'https://www.dermamina.com/profhilo',
},

]
}}
</JsonLd>


<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Anti-wrinkle</span> <span style="color: #00aec7;">Treatments</span>'
    secondText="Look Younger Feel Confident"
    videoUrl="https://www.youtube.com/watch?v=UAkFR0JoqYE" // Specify your video URL here
    videoAlt="Anti-wrinkle Video"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5 pb-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
<LipsGlance
   best="1 treatment"
  bestalt="best Anti-wrinkle therapy"
  resultstext="Up to 6 months"
  resultsalt="Anti-wrinkle therapy results"
  costdata="From £190"
  costalt="Anti-wrinkle Cost"
  timedata="10 Minutes"
  timealt="Anti-wrinkle Duration Time"
  workdata="Immediately"
  workalt="Anti-wrinkle downtime work"
  paindata="None"
  painalt="Anti-wrinkle Pain"
  />
 </Col>

  </Row>
  </Container>
  </Section>
  <Content />
  <AntiGallery />
  <Content2 />
  <LipsFaq
    setIsModalOpen={setIsModalOpen}
  />
<Testimonial />
<Clinic />   
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default ExoPage;
